<template>
  <div class="rating-grades">
    <grade-item
      v-for="item in grades"
      :item="item"
      :key="item.id"
      :current-value="currentRating"
      @updateGrade="updateGrade"
    />
  </div>
</template>

<script>
import GradeItem from '@/components/smart/CalculatorRating/GradeItem'
import { gradesValues } from '@/utils/rating'
const grades = [
  {
    id: 0,
    value: gradesValues.bad
  },
  {
    id: 1,
    value: gradesValues.neutral
  },
  {
    id: 2,
    value: gradesValues.good
  },
  {
    id: 3,
    value: gradesValues.idea
  }
]

export default {
  name: 'RatingGrades',
  props: {
    currentRating: {
      type: String,
      default: null
    }
  },
  methods: {
    updateGrade(grade) {
      this.$emit('updateGrade', grade)
    }
  },
  components: { GradeItem },
  computed: {
    grades: () => grades
    // wrapperClasses() {
    //   return {
    //     'rating-grades': true,
    //     'rating-grades--selected': !!this.currentRating
    //   }
    // }
  }
}
</script>

<style scoped lang="sass">
.rating-grades
  display: grid
  $t-s: repeat(4, 1fr)
  $t-m: 1fr 1fr
  $t-l: repeat(4, 1fr)
  +media((grid-template-columns: (0: $t-s, 480: $t-m, 992: $t-l)))
  // grid-template-columns: repeat(4, 1fr)
  grid-gap: rem(10)

// .fade-enter-active, .fade-leave-active
//   transition: opacity .5s
// .fade-enter, .fade-leave-to
//   opacity: 0
</style>
