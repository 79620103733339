<template>
  <div
    class="used-materials__table--details used-materials__table--details--junctions"
    :class="{ additional: additional }"
  >
    <div class="table-head">
      <div class="titles">
        <div class="titles__left">
          <div class="title">
            {{ $t(`message.details.system.usedMaterials.table.junctionMaterials`) }}
          </div>
        </div>
        <div class="titles__right">
          <div v-if="additional" class="title title--with-icon">
            <span>
              {{ $t(`message.details.system.usedMaterials.table.сonsumption`) }} <br />
              <span class="description">
                {{ $t(`message.details.system.usedMaterials.table.сonsumptionUnit`) }}
                <img
                  src="@/assets/img/icons/info.svg"
                  alt="icon"
                  class="icon"
                  v-tippy="{ placement: 'right', maxWidth: 200 }"
                  :content="rateHint"
                />
              </span>
            </span>
          </div>
          <div v-if="additional" class="title">
            {{ $t(`message.details.system.usedMaterials.table.ratio`) }} <br />
            <span class="description">
              {{ $t(`message.details.system.usedMaterials.table.stock`) }}
            </span>
          </div>
          <div class="title">
            {{ $t(`message.details.system.usedMaterials.table.count`) }} <br />
            <span class="description">
              {{ $t(`message.details.system.usedMaterials.table.computation`) }}
            </span>
          </div>
          <div class="title">
            {{ $t(`message.details.system.usedMaterials.table.count`) }} <br />
            <span class="description">
              {{ $t(`message.details.system.usedMaterials.table.package`) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="table-body" v-for="(item, i) in junctionDetails" :key="`junction-item-${i}`">
      <div class="table-body__title">
        <span class="text"> {{ item.name }}{{ addQuantity(item) }}</span>
      </div>
      <div
        class="table-body__item"
        v-for="(material, n) in item.materials"
        :key="`junction-item-${i}-material-${n}`"
      >
        <div class="table-body__subtitle">
          <span class="text"> {{ material.name }} </span>
        </div>
        <div class="table-body__wrapper">
          <div class="table-body__subwrapper" v-for="(m, index) in material.materials" :key="`junction-material-item-${index}`">
            <div class="table-body__item-left">
              <span class="content">{{ m.name }}</span>
            </div>
            <div class="table-body__item-right">
              <span v-if="additional" class="content">{{ getRoundedValue(m.rate) }}</span>
              <span v-if="additional" class="content">{{ getRoundedValue(m.reserve) }}</span>
              <span class="content">{{ m.count }} {{ m.units }}</span>
              <span class="content">{{ m.countPackage }} {{ m.units }}</span>
            </div>
          </div>
        </div>
      </div>
      <Message :type="'sectorNote'" v-if="i === junctionDetails.length - 1" />
    </div>
  </div>
</template>

<script>
import Message from '@/components/elements/Dom/Message'
import roundMixin from '@/mixins/RoundMixin'

export default {
  mixins: [roundMixin],
  props: {
    computation: {
      type: Array,
      default: () => [],
      required: true
    },
    additional: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Message
  },
  methods: {
    addQuantity(item) {
      return `, ${item.qty} ${item.unit}`
    }
  },
  computed: {
    junctionDetails() {
      return this.computation
    },
    rateHint() {
      const disclaimers = JSON.parse(localStorage.getItem('disclaimer'))

      return disclaimers ? disclaimers.results.materials.junctions_product_rate : ''
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/details-big-table.sass"
</style>
