<template>
  <div class="note">
    <div class="note__header">
      <h3 class="common__title-result" :class="{ unprint: existNote === ''}">{{ $t('message.notes.title') }}</h3>
      <button class="note__button" type="button" @click="editNote()"></button>
    </div>
    <textarea
        :value="existNote"
        ref="input"
        class="note__textarea"
        :class="{ 'note__textarea_hidden': !isEditMode }"
        :placeholder="$t('message.notes.placeholder')"
        @blur="saveNote($event)"
        @input="handle($event)"
    ></textarea>
    <p class="note__text" ref="text" :class="{ 'note__textarea_hidden': isEditMode }">{{ existNote }}</p>
  </div>
</template>

<script>
export default {
  props: {
    existNote: String
  },
  name: 'Note',
  data: () => ({
    isEditMode: false
  }),
  methods: {
    saveNote(evt) {
      this.editNote()
      this.$emit('input', { text: evt.target.value, isClose: true })
    },
    editNote() {
      this.isEditMode = !this.isEditMode
    },
    handle(evt) {
      this.$emit('input', { text: evt.target.value, isClose: false })
    }
  }
}
</script>

<style scoped lang="sass">
.note
  +media((width: (320: calc(100% - 32px), 650: 92%, 960: 100%)))
  +media((margin: (320: 0 auto, 960: 0)))
  &__header
    display: flex
    align-items: center
    .common__title-result
      width: auto
      +media((margin: (320: 0)))
      +media((padding: (320: rem(48) 0 rem(16), 650: rem(48) 0 rem(16), 992: rem(68) 0 rem(19))))
  &__button
    display: flex
    width: rem(24)
    height: rem(24)
    border: none
    background: url(~@/assets/img/icons/wedge--glu-edit.svg) no-repeat
    background-size: contain
    cursor: pointer
    +media((margin: (320: rem(48) rem(12) rem(16), 992: rem(68) rem(24) rem(19))))
  &__textarea
      width: 100%
      min-height: rem(100)
      padding: rem(15) rem(20)
      @extend %16
      font-family: $main-font
      color: $black_A
      border: none
      text-overflow: ellipsis
      box-sizing: border-box
      box-shadow: 0 rem(15) rem(45) rem(-27) rgba(0, 0, 0, 0.2)
      outline: none
      resize: none
      &_hidden
        display: none
        & + .note__text
          margin: 0
      &:focus::placeholder
        color: transparent
  &__text
    @extend %16
</style>
