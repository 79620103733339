<template>
  <transition name="modal">
    <div class="modal-overlay" v-if="isModalOpen" @click.self="close">
      <div class="modal-wrapper">
        <button @click="close" class="close">
          <img src="@/assets/img/close-red.svg" alt="❌" />
        </button>
        <div class="modal-body">
          <div class="text">
            <slot name="text"></slot>
          </div>
          <slot name="title"></slot>
          <div class="buttons">
            <slot name="cancel"></slot>
            <slot name="confirm"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isModalOpen: {
      type: Boolean,
      required: true
    }
  },
  name: 'ConfirmationModal',
  watch: {
    isModalOpen() {
      this.isModalOpen ? this.$root.offBodyScroll(false) : this.$root.onBodyScroll()
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="sass">
.modal-overlay
  z-index: 5
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(#000, .3)
  display: flex
  justify-content: center
  align-items: flex-start
  .modal
    &-wrapper
      margin: rem(20) 0 0
      background: $white
      box-shadow: 0 rem(1) rem(4) rgba(51, 51, 51, 0.3), 0 rem(5) rem(30) rgba(51, 51, 51, 0.2)
      border-radius: rem(4)
      +media((padding: (320: rem(30) rem(20), 480: rem(61) rem(60) rem(62))))
      width: 90vw
      max-width: rem(540)
      text-align: left
      position: relative
      .close
        background: none
        box-shadow: none
        border: none
        padding: 0
        line-height: 0
        position: absolute
        right: 0
        top: 0
        +media((height: (320: rem(30), 576: rem(40))))
        +media((width: (320: rem(30), 576: rem(40))))
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        outline: none
    &-body
      color: $black
      .text
        font-style: normal
        font-weight: normal
        font-size: rem(14)
        line-height: 150%
        +media((margin-bottom: (320: rem(20), 576: rem(32))))
      ::v-deep .title
          font-weight: bold
          line-height: rem(22)
          +media((margin-bottom: (320: rem(20), 576: rem(32))))
          +media((font-size: (320: rem(16), 576: rem(18))))
      .buttons
        display: flex
        align-items: center
        ::v-deep .btn
            box-shadow: none
            border: none
            background: none
            outline: none
            +media((padding: (320: rem(10) rem(15), 576: rem(14) rem(20))))
            border-radius: rem(4)
            font-style: normal
            font-weight: bold
            font-size: rem(14)
            cursor: pointer
            line-height: 100%
            transition: .5s
            display: flex
            align-items: center
            .icon
              margin-right: rem(20)
            &:not(:last-child)
              margin-right: rem(8)
            &--grey
              color: $dark-grey
              background: $ec
              @media(any-hover: hover)
                &:hover
                  background: #cbcbcb
            &--red
              color: $white
              background: $red
              @media(any-hover: hover)
                &:hover
                  background: $red-hover
            &--green
              color: $white
              background: $green
              @media(any-hover: hover)
                &:hover
                  background: $green-hover
.modal-enter-active, .modal-leave-active
  transition: .5s
.modal-enter, .modal-leave-to
  -webkit-transform: scale(1.1)
  opacity: 0
  transform: scale(1.1)
</style>
