<template>
  <div class="prefooter" ref="prefooter">
    <div class="prefooter__buttons">
      <a @click="toCalculationLink()" class="prefooter__buttons-btn prefooter__buttons-btn--save">
        <img class="img" src="@/assets/img/icons/cloud.svg" alt="save" />
        <div class="content">
          <div class="content__title">
            {{ $t('message.preFooter.save') }}
          </div>
          <div class="content__text">
            {{ $t('message.preFooter.saveText') }}
          </div>
        </div>
      </a>
      <div @click="savePdf" class="prefooter__buttons-btn prefooter__buttons-btn--download">
        <inline-svg :src="require('@/assets/img/footer/file.svg')" />
        <div class="title">
          <i18n path="message.preFooter.download">
            <template #break>
              <br />
            </template>
            <template #file>
              <b>{{ $t('message.preFooter.pdf') }}</b>
            </template>
          </i18n>
        </div>
      </div>
      <div @click="saveXls" class="prefooter__buttons-btn prefooter__buttons-btn--download">
        <inline-svg :src="require('@/assets/img/footer/file.svg')" />
        <div class="title">
          <i18n path="message.preFooter.download">
            <template #break>
              <br />
            </template>
            <template #file>
              <b>{{ $t('message.preFooter.xls') }}</b>
            </template>
          </i18n>
        </div>
      </div>
      <div @click="print" class="prefooter__buttons-btn prefooter__buttons-btn--download">
        <inline-svg :src="require('@/assets/img/footer/print.svg')" />
        <div class="title">
          <i18n path="message.preFooter.print">
            <template #break>
              <br />
            </template>
          </i18n>
        </div>
      </div>
      <div
        @click="toggleConfirmationModal"
        class="prefooter__buttons-btn prefooter__buttons-btn--new"
      >
        <img src="@/assets/img/prefooter--new-calculation.svg" alt="new" />
        <div class="title">
          <b>
            <i18n path="message.preFooter.new">
              <template #break>
                <br />
              </template>
            </i18n>
          </b>
        </div>
      </div>
      <confirmation-modal :is-modal-open="isModalOpen" @close="toggleConfirmationModal">
        <template #text> {{ $t('message.preFooter.newConfirmation.text') }} </template>
        <template #cancel>
          <button v-if="!saveCalculationLink" class="btn btn--red" @click="toCalculationLink()">
            <img src="@/assets/img/icons/cloud--mini.svg" alt="cloud" class="icon" />
            {{ $t('message.preFooter.newConfirmation.confirm') }}
          </button>
        </template>
        <template #confirm>
          <button class="btn btn--green" @click="startNewCalculation">
            <img src="@/assets/img/icons/new-calculation.svg" alt="new" class="icon" />
            {{ $t('message.preFooter.newConfirmation.cancel') }}
          </button>
        </template>
      </confirmation-modal>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { mapState, mapActions } from 'vuex'
import ConfirmationModal from '@/components/elements/Modals/ConfirmationModal'
import { sendStatistic, statisticLabels } from '@/utils/sendStatistic'

export default {
  props: {
    isInDetails: Boolean
  },
  data: () => ({
    isHover: false,
    isModalOpen: false,
    isLoading: false
  }),
  methods: {
    ...mapActions({
      startNewCalculation: 'startNewCalculation'
    }),
    toggleConfirmationModal() {
      this.isModalOpen = !this.isModalOpen
    },
    print() {
      window.print()
      sendStatistic(statisticLabels.prefooter.print)
    },
    savePdf() {
      this.$emit('savePdf')
      sendStatistic(statisticLabels.prefooter.pdf)
    },
    saveXls() {
      this.$emit('saveXls')
      sendStatistic(statisticLabels.prefooter.xls)
    },
    toCalculationLink() {
      if (!this.saveCalculationLink) {
        sendStatistic(statisticLabels.prefooter.save)
        window.parent.postMessage({ resultId: this.user.result?.id }, '*')
        window.top.location = this.user.result?.link
      } else {
        window.parent.postMessage({ scrollTop: true }, '*')
        this.$notify({
          group: 'foo',
          type: 'warn',
          title: this.$t('message.save.noNeedSaving')
        })
      }
    }
  },
  components: {
    InlineSvg,
    ConfirmationModal
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors,
      result: state => state.result,
      user: state => state.user,
      saveCalculationLink: state => state.saveCalculationLink
    }),
    calculationLink() {
      return this.user.result?.link
    }
  },
  beforeDestroy() {
    this.$root.onBodyScroll()
  }
}
</script>
<style lang="sass">
.prefooter__buttons-btn
  &--download, &--new
    @media(any-hover: hover)
      &:hover svg path
        stroke: $white
</style>
<style lang="sass" scoped>
.prefooter
  background: $white

  &__buttons
    display: grid
    $t-xl: 20fr repeat(3, 9fr) 9fr
    $t-l: repeat(3, 1fr)
    $t-m: repeat(4, 1fr)
    $t-s: 1fr 1fr
    +media((grid-template-columns: (320: $t-s, 576: $t-m, 768: $t-l, 960: $t-xl)))
    max-width: rem(1120)
    width: 100%
    +media((margin: (320: rem(48) auto 0, 960: rem(68) auto 0)))
    &-btn
      min-height: rem(153)
      &:not(:last-child)
        +media((box-shadow: (768: inset rem(-1) 0 0 $ec)))
      &--download, &--new
        color: $black
        background: $white

        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        transition: .5s
        cursor: pointer
        text-decoration: none

        .title
          font-weight: normal
          font-size: rem(14)
          line-height: 130%
          text-align: center
        svg
          path
            transition: .5s
        @media(any-hover: hover)
          &:hover
            background: $red
            color: $white
      &--download
        .title
          margin-top: rem(26)
      &--new
        +media((grid-area: (320: '2 / 2 / 3 / 3', 576: '2 / 4 / 2 / 5', 768: '2 / 1 / 3 / 2', 960: auto)))
        .title
          margin-top: rem(14)
      &--save
        +media((grid-column: (320: '1 / 3', 576: '1 / 5', 768: '1 / 4', 960: auto)))
        +media((padding: (320: 0 rem(25), 768: 0 rem(40) 0 0)))
        background: $red
        display: flex
        flex-direction: row
        align-items: center
        text-decoration: none
        transition: .5s
        position: relative
        cursor: pointer
        @media(any-hover: hover)
          &:hover
            background: $red-hover
        .img
          pointer-events: none
          +media((padding: (320: 0 rem(24) 0 0, 768: 0 rem(38), 960: 0 rem(18), 1120: 0 rem(38))))
        .content
          display: flex
          flex-flow: row wrap
          margin: 0
          color: $white
          text-align: left
          pointer-events: none
          &__title
            width: 100%
            font-style: normal
            font-weight: bold
            margin-bottom: rem(12)
            +media((font-size: (320: rem(20), 960: rem(18))))
            +media((line-height: (320: rem(24), 960: rem(22))))
          &__text
            font-style: normal
            font-weight: 600
            font-size: rem(12)
            line-height: 150%
</style>
