<script>
export default {
  name: 'RoundMixin',
  methods: {
    getRoundedValue(value) {
      if (!value) {
        return ''
      }

      return /\.|,/gm.test(value) ? this.deleteNull(parseFloat(value).toFixed(2)) : value
    },
    deleteNull(value) {
      if (/.*0$/gm.test(value)) {
        const nullIndex = 3
        return value.split('').splice(0, nullIndex).join('')
      }

      return value
    }
  }
}
</script>

<style scoped>

</style>
