<template>
  <div class="used-materials" v-if="isShowComputation">
    <div class="details-title">
      {{ $t('message.result.usedMaterials.table.title') }}
    </div>
    <div class="used-materials__table">
      <div class="table-head">
        <div class="titles">
          <div class="titles__left">
            <div class="title">
              {{ $t('message.result.usedMaterials.table.material') }}
            </div>
          </div>
          <div class="titles__right">
            <div class="title">
              {{ $t('message.result.usedMaterials.table.count') }} <br />
              <span class="description">
                {{ $t('message.result.usedMaterials.table.computation') }}
              </span>
            </div>
            <div class="title">
              {{ $t('message.result.usedMaterials.table.count') }} <br />
              <span class="description description--md">
                {{ $t('message.result.usedMaterials.table.package') }}
              </span>
              <span class="description description--xs">
                {{ $t('message.result.usedMaterials.table.packageMob') }}
              </span>
            </div>
            <div class="title title--md">
              {{ $t('message.result.usedMaterials.table.sectors') }}
            </div>
            <div class="title title--xs">
              {{ $t('message.result.usedMaterials.table.sectorsMob') }}
            </div>
          </div>
        </div>
      </div>
      <div class="table-body">
        <div
          class="table-body__item"
          :class="{ active: currentTableItemExpand === `table-item-${i}` }"
          v-for="(item, i) in filteredComputation"
          :key="i"
        >
          <div class="table-body__item-left">
            <div class="content">{{ item.name }}</div>
          </div>
          <div class="table-body__item-right">
            <div class="content">{{ item.count }} {{ item.units }}</div>
            <div class="content">{{ item.countPackage }} {{ item.units }}</div>
            <div class="content count">{{ item.indices.length }}</div>
            <button
              class="chevron"
              :class="{ 'chevron--active': currentTableItemExpand === `table-item-${i}` }"
              @click="toggleTableItem(`table-item-${i}`)"
            >
              <img src="@/assets/img/icons/table-chevron.svg" alt="↓" />
            </button>
          </div>
          <Result-used-materials-nav
            v-if="currentTableItemExpand === `table-item-${i}`"
            :indices="item.indices"
          />
        </div>
      </div>
      <div
        class="table-footer"
        :class="{ active: isShowMore }"
        @click="isShowMore = !isShowMore"
        v-if="computation.length > 7"
      >
        <div class="table-footer__content">
          <div class="text">
            <span
              v-text="
                isShowMore
                  ? `${$t('message.result.usedMaterials.table.hide')}`
                  : `${$t('message.result.usedMaterials.table.show')}`
              "
            ></span>
            {{ computation.length }}
          </div>
          <img src="@/assets/img/icons/filter--select.svg" alt="↓" class="chevron" />
        </div>
      </div>
    </div>
    <Disclaimer :defaultValue="disclaimer" @changeOption="changeDisclaimer($event)"></Disclaimer>
  </div>
</template>

<script>
import ResultUsedMaterialsNav from '@/components/smart/Result/ResultUsedMaterialsNav'
import { mapActions, mapState } from 'vuex'
import Disclaimer from '@/components/dump/Disclaimer'
export default {
  data: () => ({
    currentTableItemExpand: null,
    isShowMore: false
  }),
  components: {
    ResultUsedMaterialsNav,
    Disclaimer
  },
  methods: {
    ...mapActions({
      updateDisclaimer: 'updateDisclaimer'
    }),
    toggleTableItem(val) {
      this.currentTableItemExpand = this.currentTableItemExpand === val ? null : val
    },
    changeDisclaimer(text) {
      this.updateDisclaimer(text)
    }
  },
  computed: {
    ...mapState({
      result: state => state.result
    }),
    isShowComputation() {
      return (
        Object.prototype.hasOwnProperty.call(this.result, 'total') &&
        this.result.total.computation.length > 0
      )
    },
    computation() {
      return this.result.total.computation
    },
    filteredComputation() {
      if (this.isShowMore) {
        return this.computation
      }
      return this.computation.slice(0, 7)
    },
    disclaimer() {
      return this.result.disclaimer
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/result-used-materials.sass"
</style>
