<template>
  <div class="unprint addons" v-if="isShowAlbums">
    <div class="common__title-result">
      {{ $t('message.addons.title') }}
    </div>
    <div class="addons__items">
      <div class="addons__item" v-for="album in albumsForDisplay" :key="album.id">
        <a :href="album.link" class="addons__link" target="_blank">
          <img src="@/assets/img/icons/download.svg" alt="downlad" class="addons__link-img" />
          {{ album.name }}
          <!-- <div class="addons__item" v-for="album in currentAlbums" :key="album">
        <a :href="getAlbum(album).link" class="addons__link" target="_blank">
          <img src="@/assets/img/icons/download.svg" alt="downlad" class="addons__link-img" />
          {{ getAlbum(album).name }} -->
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    placement: {
      type: String,
      default: 'total'
    }
  },
  // methods: {
  //   getAlbum(id) {
  //     return this.allAlbums.find(p => p.id === id)
  //   }
  // },
  computed: {
    ...mapState({
      allAlbums: state => state.albums,
      result: state => state.result
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    currentSectorResult() {
      return this.result.sectors[this.sectorIndex]
    },
    albumsForDisplay() {
      return this.currentAlbums.map(album => {
        return { ...this.allAlbums.find(item => item.id === album) }
      })
    },
    isShowAlbums() {
      if (this.allAlbums.length > 0) {
        if (this.placement === 'total') {
          return (
            Object.prototype.hasOwnProperty.call(this.result, 'total') &&
            this.result.total.albums.length > 0
          )
        } else if (this.placement === 'sector') {
          return (
            Object.prototype.hasOwnProperty.call(this.currentSectorResult, 'albums') &&
            this.currentSectorResult.albums.length > 0
          )
        } else if (this.placement === 'junction') {
          return (
            Object.prototype.hasOwnProperty.call(this.currentSectorResult, 'junctionsAlbums') &&
            this.currentSectorResult.junctionsAlbums[this.junctionIndex].length > 0
          )
        } else {
          return false
        }
      } else {
        return false
      }
    },
    currentAlbums() {
      if (this.placement === 'total') {
        return this.result.total.albums
      } else if (this.placement === 'sector') {
        return this.currentSectorResult.albums
      } else if (this.placement === 'junction') {
        return this.currentSectorResult.junctionsAlbums[this.junctionIndex]
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.addons
  +media((width: (320: calc(100% - 32px), 650: 92%, 960: 100%)))
  +media((margin: (320: 0 auto, 960: 0)))
.common__title-result
  box-sizing: border-box
  +media((margin: (0: rem(35) 0 rem(19), 960: rem(51) 0 rem(19))))
  padding: 0
  &__items
    display: grid
    +media((grid-template-columns: (320: 1fr, 768: repeat(2, 1fr))))
</style>
