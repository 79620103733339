<template>
  <section class="result-page">
    <div class="container">
      <div class="row">
        <SaveNotification
          v-if="saveCalculationLink && publicLink"
          :link="saveCalculationLink"
          :publicLink="publicLink"
        />
      </div>
    </div>
    <div class="container">
      <h2 class="result-page__title unprint">
        {{ $t('message.result.head.title') }}
      </h2>
      <div class="common-pane result-page__pane">
        <div class="pane-item">
          <div class="count">{{ sectorsCount }}</div>
          <div class="text">{{ getFormattedWord('sector') }}</div>
        </div>
        <div class="pane-item">
          <div class="count">{{ junctionsCount }}</div>
          <div class="text">{{ getFormattedWord('junctions') }}</div>
        </div>
        <div class="pane-item">
          <div class="text">
            {{ $t('message.result.head.square') }}
          </div>
          <div class="count">
            {{ sumSquare(allSectors) }} <span class="units">{{ $t('message.units.m2') }}</span>
          </div>
        </div>
      </div>
      <Result-sectors-nav @toggleDrawing="toggleDrawing($event)" placement="total" />
      <component :is="drawComponent" :drawing="showSectorDrawing" placement="result" @close="closeDrawing"/>
      <Result-used-materials />
      <Result-used-materials-by-sectors />
      <Note :existNote="calculationNote" @input="updateCalculationNote($event)" />
      <Addons placement="total" />
      <calculator-rating />
    </div>
    <Prefooter @savePdf="savePdf" @saveXls="saveXls" />
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </section>
</template>

<script>
import { pages } from '@/utils'
import { mapState, mapActions, mapGetters } from 'vuex'
import { sumElements, formatWord } from '@/utils/customFunctions'
import { isResultPagePermitted } from '@/middlewares'
import ResultSectorsNav from '@/components/smart/ResultSectorsNav'
import ResultUsedMaterials from '@/components/smart/Result/ResultUsedMaterials'
import ResultUsedMaterialsBySectors from '@/components/smart/Result/ResultUsedMaterialsBySectors'
import Addons from '@/components/dump/Addons'
import Note from '@/components/dump/Note'
import Prefooter from '@/components/dump/Prefooter'
import CalculatorRating from '@/components/smart/CalculatorRating'
import { stepNumbers } from '@/utils/stepNumbers'
import SaveNotification from '../components/dump/SaveNotification.vue'
export default {
  components: {
    SaveNotification,
    CalculatorRating,
    ResultSectorsNav,
    Addons,
    Prefooter,
    ResultUsedMaterials,
    ResultUsedMaterialsBySectors,
    Note
  },
  data: () => ({
    calculationNote: null,
    showSectorDrawing: null,
    drawComponent: null
  }),
  methods: {
    ...mapActions({
      updateStep: 'updateStep',
      getResults: 'getResults',
      getSavedProject: 'getSavedProject',
      fetchData: 'fetchData',
      getPdf: 'getPdf',
      getXls: 'getXls',
      checkTNTokenAction: 'checkTNToken',
      saveNote: 'saveNote',
      addDefaultDisclaimer: 'addDefaultDisclaimer',
      closeWebsocketConnection: 'closeWebsocketConnection'
    }),
    ...mapGetters({
      getNote: 'getNote'
    }),
    sumSquare(arr) {
      return sumElements(arr, 'square')
    },
    getFormattedWord(type) {
      if (type === 'sector') {
        return formatWord(this.$t('message.result.head.words').sectors, this.sectorsCount)
      } else {
        return formatWord(this.$t('message.result.head.words').junctions, this.junctionsCount)
      }
    },
    savePdf() {
      this.getPdf({
        lang: this.$i18n.locale,
        type: 'total',
        fileName: this.$t('message.result.save.fileName'),
        domenName: `${location.origin}/${this.$i18n.locale}`,
        header: {
          sectorsCount: this.sectorsCount,
          junctionsCount: this.junctionsCount,
          square: this.sumSquare(this.allSectors)
        },
        page: pages.result
      })
    },
    saveXls() {
      this.getXls({
        lang: this.$i18n.locale,
        type: 'total',
        fileName: this.$t('message.result.save.fileName'),
        sectorIndex: null,
        junctionIndex: null,
        title: null,
        page: pages.result
      })
    },
    updateCalculationNote(note) {
      this.calculationNote = note.text
      if (note.isClose) {
        this.saveNote(note.text)
      }
    },
    toggleDrawing(evt) {
      this.drawComponent = () => import('@/components/dump/DrawingComponent')
      this.showSectorDrawing = this.showSectorDrawing ? null : this.allSectors[evt.index]?.sectorImg
    },
    closeDrawing() {
      this.showSectorDrawing = null
      this.drawComponent = null
    },
    async closeSocket() {
      try {
        this.socket.socket.removeAllListeners()
        this.socket.closeWindowListener()
        await this.closeWebsocketConnection()
      } catch (e) {
      }
    }
  },
  async mounted() {
    const { object: hash, tnt: token } = this.$route.query
    if (token) {
      await this.checkTNTokenAction(token, this.$i18n.locale)
    }
    if (hash) {
      this.fetchData(this.$i18n.locale).then(() => {
        this.getSavedProject({
          lang: this.$i18n.locale,
          hash,
          page: 'result'
        })
      })
    } else {
      if (this.allSystems.length === 0 || !isResultPagePermitted(this.allSectors)) {
        await this.$router.push(`/${this.$i18n.locale}/`)
      } else {
        await this.getResults(this.$i18n.locale)
      }
    }
    await this.updateStep(stepNumbers.result)
    this.calculationNote = this.getNote()
    await this.addDefaultDisclaimer(JSON.parse(localStorage.getItem('disclaimer')).main[0])
    await this.closeSocket()
  },
  computed: {
    ...mapState({
      allSectors: state => state.sectors,
      isLoading: state => state.pages.result.isLoading,
      saveCalculationLink: state => state.saveCalculationLink,
      userInfo: state => state.user,
      publicLinkTemplate: state => state.publicLink,
      socket: state => state.websocket
    }),
    ...mapGetters({
      allSystems: 'allPublishedSystems'
    }),
    sectorsCount() {
      return this.allSectors.length
    },
    junctionsCount() {
      return this.allSectors.reduce(
        (accumulator, currentValue) => accumulator + currentValue.junctions.length,
        0
      )
    },
    publicLink() {
      return this.publicLinkTemplate && this.userInfo.result?.id
        ? `${this.publicLinkTemplate}${this.userInfo.result?.id}`
        : ''
    }
  }
}
</script>

<style scoped lang="sass">
.result-page
  &__title
    font-weight: bold
    +media((font-size: (320: rem(18), 960: rem(24))))
    +media((padding: (0: rem(32) 0 rem(16), 960: rem(35) 0 rem(20))))
    +media((margin: (0: 0 auto, 960: 0)))
    +media((width: (320: calc(100% - 32px), 650: 92%, 960: 100%)))
  &__pane
    padding: rem(16) 0
    flex-direction: row
    +media((align-items: (320: flex-start, 960: center)))
    justify-content: flex-start
    +media((flex-wrap: (320: wrap, 400: nowrap)))
    +media((display: (320: flex, 400: grid, 480: flex)))
    grid-template-columns: 1fr 1fr 1fr
    .pane-item
      box-sizing: border-box
      display: flex
      justify-content: flex-start
      height: 100%
      +media((align-items: (320: flex-start, 960: center)))
      +media((flex-direction: (320: column, 960: row)))
      +media((padding: (320: 0 rem(24), 400: 0 0 0 rem(24), 480: 0 rem(24), 960: 0 rem(32))))
      &:not(:first-child)
        box-shadow: inset rem(1) 0 0 #ECECEC
      &:not(:last-child)
        .text
          +media((padding-left: (320: 0, 960: rem(16))))
      &:last-child
        +media((margin-left: (320: unset, 960: auto)))
        +media((flex-direction: (320: column-reverse, 960: row)))
        +media((padding-top: (320: rem(16), 400: 0)))
        .text
          +media((padding-right: (320: 0, 960: rem(16))))
      .count
        font-style: normal
        font-weight: bold
        +media((font-size: (320: rem(24), 960: rem(28))))
        +media((padding-bottom: (320: rem(4), 960: 0)))
        line-height: 140%
        color: $black
      .text
        @extend .fs-14
        +media((font-size: (320: rem(12), 960: rem(14))))
</style>
