<template>
  <div class="result-page__sectors unprint">
    <h2 class="title">{{ $t('message.result.nav.title') }}</h2>
    <p class="content__panes-info">
      <span class="text">{{ $t('message.result.nav.info') }}</span>
    </p>
    <article class="result-page__sectors-nav">
      <div v-for="(sector, i) in allSectors" :key="`sector-${i}`" class="link__wrapper">
        <router-link :to="`/${$i18n.locale}/details/${i}`" class="link" :class="{ 'link--custom-sector': sector.isCustom }">
          <div class="link__content">
            <h3 class="link__title">{{ sector.name }}</h3>
            <span class="link__info link__info--square">{{ format(sector.square) }} {{ $t('message.units.m2') }}</span>
            <span class="link__info link__info--junctions">{{ sum(sector.junctions) }}</span>
          </div>
        </router-link>
        <button v-if="placement === 'total' && sector.sectorImg" class="link__drawing" type="button" @click="toggleDrawingVisibility(i)">
          {{ $t('message.cad.drawing') }}
        </button>
      </div>
      <button class="add" @click="addNewSector">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><circle cx="12" cy="12" r="12" fill="#2ECC71"/><path stroke="#fff" stroke-linejoin="round" stroke-miterlimit="22.926" stroke-width="2" d="M12 18V6m6 6H6"/></svg>
      </button>
    </article>
  </div>
</template>

<script>
import { formatBigNumber } from '@/utils/customFunctions'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  inject: ['slideToLast'],
  props: ['placement'],
  methods: {
    ...mapMutations({
      ADD_NEW_SECTOR: 'ADD_NEW_SECTOR'
    }),
    ...mapActions({
      markCalculationAsSaved: 'markCalculationAsSaved',
      resetUserInfoResults: 'resetUserInfoResults'
    }),
    format(n) {
      return formatBigNumber(n)
    },
    sum(arr) {
      return arr?.length
    },
    addNewSector() {
      this.ADD_NEW_SECTOR()
      if (this.isSavedCalculation) {
        this.resetUserInfoResults()
        this.markCalculationAsSaved(null)
      }
      this.$router.push(`/${this.$i18n.locale}/systems/${this.allSectors.length - 1}`)
    },
    toggleDrawingVisibility(index) {
      this.$emit('toggleDrawing', { index })
    }
  },
  computed: {
    ...mapState({
      allSectors: state => state.sectors,
      isSavedCalculation: state => state.user.objectId
    })
  }
}
</script>

<style scoped lang="sass">
.result-page
  &__sectors
    +media((padding-top: (320: rem(32), 960: rem(51))))
    +media((width: (320: calc(100% - 32px), 650: 92%, 960: 100%)))
    margin: 0 auto
    .title
      @extend .fs-24
      +media((font-size: (320: rem(18), 960: rem(24))))
      +media((padding-bottom: (320: rem(12), 960: rem(20))))
      font-weight: bold
    .content__panes-info
      padding: 0
      margin: 0 0 rem(18)
      background: url(~@/assets/img/icons/info.svg) no-repeat center left
      .text
        padding: 0 0 0 rem(35)
    &-nav
      .link
        &__wrapper:not(:has(.link__drawing))
          .link
            height: 100%
      display: grid
      align-items: center
      +media((grid-template-columns: (320: 1fr, 480: repeat(2, 1fr), 576: repeat(3, 1fr), 650: repeat(4, 1fr), 800: repeat(5, 1fr), 960: repeat(6, 1fr))))
      .link
        background: #FFFFFF
        cursor: pointer
        transition: .5s
        text-decoration: none
        display: flex
        +media((flex-direction: (320: row, 576: column)))
        align-items: center
        justify-content: flex-start
        +media((padding: (320: rem(16) rem(16) rem(16) rem(8), 576: rem(16), 960: rem(29) rem(12) rem(9))))
        +media((gap: (0: rem(10), 576: 0)))
        border: rem(1) solid #ECECEC
        box-sizing: border-box
        &:before
          content: ''
          display: flex
          width: 100%
          +media((width: (320: 40%, 576: 100%)))
          height: rem(34)
          background: url(~@/assets/img/nav/slide-tn--color.svg) no-repeat center center
        &--custom-sector
          &:before
          background-image: url(~@/assets/img/nav/slide-custom--color.svg)
        &__content
          display: flex
          justify-content: space-between
          flex-wrap: wrap
          +media((width: (320: auto, 576: 100%)))
          width: 100%
          min-height: rem(80)
        &__title
          width: 100%
          margin: 0
          +media((padding: (320: 0 0 rem(4), 576: rem(17) 0 rem(8))))
          @extend .fs-12
          text-align: left
          font-weight: bold
          color: $black
        &__drawing
          display: flex
          justify-content: center
          gap: rem(10)
          width: 100%
          padding: rem(10) rem(12)
          background: $white
          border: rem(1) solid #ECECEC
          @extend .fs-12
          &:before
            content: ''
            display: flex
            width: rem(20)
            height: rem(16)
            background: url(~@/assets/img/nav/nav-drawing.svg) no-repeat left center
          &:hover
            cursor: pointer
        &__info
          display: flex
          align-items: center
          padding: 0 0 0 rem(30)
          @extend .fs-12
          color: $dark-grey
          background-position: left center
          background-repeat: no-repeat
          &--square
            background-image: url(~@/assets/img/nav/slide-square.svg)
          &--junctions
            background-image: url(~@/assets/img/nav/slide-junctions.svg)
      .add
        @extend .clear-btn
        background: rgba(255, 255, 255, 0.5)
        border: rem(1) dashed #D6D6D6
        box-sizing: border-box
        border-radius: rem(4)
        +media((min-height: (320: rem(56), 576: rem(136))))
        max-height: rem(144)
        +media((margin: (320: 0, 576: rem(8))))
        svg
          path, circle
            transition: .5s
        &:hover
          background: $green
          svg
            path
              stroke: $green
            circle
              fill: #fff
</style>
