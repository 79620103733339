<template>
  <div class="wrapper">
    <div class="disclaimer-select unprint" :class="{ active: isShowOptions }" v-click-outside="hideOptions">
      <div @click="isShowOptions = !isShowOptions" class="disclaimer-select__box">
        <div class="disclaimer-select__btn">
          <img :src="selectImg" alt="" />
        </div>
      </div>
      <div class="options" v-if="isShowOptions">
        <label :for="`disclaimer-${i}`" v-for="(_, i) in disclaimers.main" :key="`disclaimer-${i}`">
          <input
              v-model="index"
              hidden
              type="radio"
              :value="i"
              :id="`disclaimer-${i}`"
              @change="changeOption(disclaimers.main[i])"
          />
          <span class="name">{{ $t('message.notes.commonTitle') }} {{ i + 1 }}</span>
        </label>
      </div>
    </div>
    <div class="disclaimer" v-html="defaultValue"></div>
  </div>
</template>

<script>
export default {
  name: 'Disclaimer',
  props: ['defaultValue'],
  data: () => {
    return {
      index: 0,
      isShowOptions: false
    }
  },
  computed: {
    disclaimers() {
      return JSON.parse(localStorage.getItem('disclaimer'))
    },
    selectImg() {
      return this.isShowOptions
        ? require('@/assets/img/icons/chevron.svg')
        : require('@/assets/img/icons/menu.svg')
    }
  },
  methods: {
    hideOptions() {
      if (this.isShowOptions) this.isShowOptions = false
    },
    changeOption(text) {
      this.hideOptions()
      this.$emit('changeOption', text)
    }
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  box-sizing: border-box
  position: relative
  +media((width: (320: auto, 650: 92%, 960: 100%)))
  +media((padding: (320: 0 rem(20) 0 rem(12), 650: 0 rem(20) 0 0)))
  +media((margin: (320: rem(15) 0 0, 650: rem(15) auto 0, 960: rem(15) 0 0)))
.disclaimer
  width: 100%
  color: $dark-grey
  font-weight: 500
  +media((font-size: (320: rem(10), 650: rem(14))))
  &::v-deep
    > *
      &:first-child
        margin-top: 0
      &:last-child
        margin-bottom: 0
  &::v-deep
    a
      color: $red
  &-select
    margin-right: rem(5)
    position: absolute
    right: 0
    height: rem(40)
    font-family: $main-font
    font-style: normal
    font-weight: bold
    line-height: rem(15)
    font-size: rem(14)
    cursor: pointer
    &__btn
      filter: invert(17%) sepia(0%) saturate(0%) hue-rotate(44deg) brightness(93%) contrast(87%)
      path
        transition: .5s
    .options
      width: rem(172)
      color: $black
      display: flex
      flex-direction: column
      background: $white
      z-index: 3
      border: rem(1) solid $grey
      border-radius: rem(4) rem(4) 0 0
      position: absolute
      box-sizing: border-box
      bottom: 100%
      right: 0
      label
        position: relative
        font-style: normal
        font-weight: bold
        font-size: rem(14)
        line-height: rem(22)
        opacity: 0.9
        text-align: left
        height: rem(34)
        box-shadow: inset 0 rem(-1) 0 $ec
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 0 rem(14)
        transition: .5s
        cursor: pointer
        width: 100%
        &:hover
          color: $red
        .name
          min-width: rem(36)
</style>
