<script>
import SimpleButton from '@/components/elements/Dom/Simple-button.vue'

export default {
  name: 'SaveNotification',
  components: { SimpleButton },
  data: () => ({
    isHidden: false
  }),
  props: {
    link: String,
    publicLink: String
  },
  computed: {
    links() {
      return {
        public: this.publicLink,
        private: this.link
      }
    },
    buttons() {
      return [
        { name: 'public', label: 'Поделиться расчётом' },
        { name: 'private', label: 'Открыть расчёт в ЛК' }
      ]
    }
  },
  methods: {
    copy(name) {
      navigator.clipboard.writeText(this.links[`${name}`])
      this.$notify({
        group: 'foo',
        type: 'success',
        title: this.$t('message.save.successfulCopy')
      })
    }
  }
}
</script>

<template>
  <div class="save-notification" :class="{ hide: isHidden }">
    <header class="save-notification__header">
      <button @click="isHidden = true" class="close">
        <img src="@/assets/img/icons/uploader--modal-close.svg" alt="❌" />
      </button>
    </header>
    <article class="save-notification__wrapper">
      <ul class="list">
        <li class="list__item" v-for="button of buttons" :key="`${button.name}-link`">
          <simple-button color="red" @clickedFromSimpleBtn="copy(button.name)">{{
            button.label
          }}</simple-button>
        </li>
      </ul>
    </article>
    <footer class="save-notification__footer">
      <img src="@/assets/img/info.svg" alt="i" class="image" />
      <div class="text">{{ $t('message.save.successfulSaving') }}</div>
    </footer>
  </div>
</template>

<style scoped lang="sass">
.save-notification
  position: relative
  padding: rem(40) rem(15) 0
  margin: 0
  width: 100%

  +media((padding: (320: 0 rem(24), 992: 0)))
  &__header
    display: flex
    justify-content: flex-end
    .close
      @extend .clear-btn
      height: rem(40)
      width: rem(40)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
  &__wrapper
  .list
    display: flex
    gap: rem(15)
    margin: 0
    padding: 0
    list-style: none
    &__item
      button
        line-height: 1.2
  &__footer
    display: flex
    gap: rem(20)
    margin: rem(30) 0 0
    .text
      @extend %16
      text-align: left
  &.hide
    display: none
</style>
